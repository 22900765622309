footer {
    @apply bg-blue-800;
    color: #fff;
    padding: 20px;
}

.footer-container {
    display: flex;
    justify-content: center;
}

.copyright {
    margin-top: 20px;
    text-align: center;
}