.gallery {
    text-align: center;
    padding: 20px;
}

.gallery img {
    max-width: 80%;
    margin: 20px auto 20px auto;
    text-align: center;
}

.gallery button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.gallery button:hover {
    background: #ddd;
}

.gallery .prev-button {
    margin-right: 10px;
}

.gallery .next-button {
    margin-left: 10px;
}

@media (max-width: 500px) {
    .gallery img {
        max-width: 100%;
    }

    .gallery button {
        display: block;
        margin: 10px auto;
    }
}