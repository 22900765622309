.customDesign .message {
    border: 2px solid #d9750a;
    border-radius: 4px;
    margin: 20px 0;
    padding: 20px;
    text-align: center;
    font-weight: bold;
}

.customDesign .message p {
    margin: 0;
    color: #c90f1c;
    text-transform: uppercase;
}