@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
    --primary-color: #1a2e5e;
    --secondary-color: #f36f37;
}

@layer base {

    body {
        @apply bg-blue-600 text-white;
        margin: 0;
        padding: 0;
    }


    button {
        @apply bg-orange-500 text-white font-bold px-4 py-2 rounded;
    }

    button[type='submit'],
    button[type='button'] {
        @apply bg-orange-500;
    }

    h1 {
        @apply text-2xl font-bold mb-4;
    }

    h2 {
        @apply text-xl;
    }

    html {
        @apply bg-blue-800;
    }

    input,
    select,
    textarea {
        @apply bg-blue-900 text-white px-2 py-1 rounded;
    }
}

.page {
    @apply max-w-2xl;
    margin: 20px auto;
}

.page h1 {
    text-align: center;
}

.page img {
    margin-right: 20px;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .page img {
        float: none;
        display: block;
        margin: 0 auto;
    }
}

.page p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.page p:first-of-type {
    margin-top: 0;
}

.page p:last-child {
    margin-bottom: 0;
}